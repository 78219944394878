import * as React from 'react';
import { styled } from '../styled';

const EmojiContainer = styled.div`
  display: inline;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export interface EmojiProps {
  array?: string[];
}

export const DynamicEmoji: React.FC<EmojiProps> = ({ array = ['🤟', '💪', '👌'] }) => {
  const [emojiIndex, setEmojiIndex] = React.useState(0);

  return (
    <EmojiContainer
      onClick={() => {
        setEmojiIndex((emojiIndex + 1) % array.length);
      }}
    >
      {array[emojiIndex]}
    </EmojiContainer>
  );
};
