import React, { useEffect } from 'react';
import { DynamicEmoji } from './components/DynamicEmoji';
import { styled } from './styled';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { TypeAnimation } from 'react-type-animation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  background-color: #282c34;
`;

const IntroductionContainer = styled.div`
  /* border: 5px solid white; */
  padding: 20px;
  width: 50%;
  text-align: center;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.huge};
    color: ${({ theme }) => theme.colors.white};
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSize.large};
    color: ${({ theme }) => theme.colors.white};
  }

  div {
    font-size: ${({ theme }) => theme.fontSize.huge};
  }
`;

const SocialContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 50px;
`;

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <RootContainer>
      <IntroductionContainer data-aos="fade-up">
        <h1>
          Hey There! <DynamicEmoji array={['👋', '🇧🇪', '👨‍💻', '👌', '🛫', '🇪🇺', '🍺', '🇦🇺', '🦘', '🤙']} />
        </h1>
        <TypeAnimation
          sequence={[
            'My Name is Nicola Macoir',
            1000,
            "I'm a full stack developer",
            1000,
            'I live in Ghent, Belgium',
            1000,
            "Let's work together?",
            5000
          ]}
          wrapper="h3"
          cursor={false}
          repeat={Infinity}
        />
        <SocialContainer>
          <a href="https://www.linkedin.com/in/nicola-macoir-49989a80/">
            <FontAwesomeIcon icon={faLinkedin} color="white" size="lg" />
          </a>
          <a href="https://github.com/nicolamacoir">
            <FontAwesomeIcon icon={faGithub} color="white" size="lg" />
          </a>
          <a href="https://twitter.com/NMacoir">
            <FontAwesomeIcon icon={faTwitter} color="white" size="lg" />
          </a>
        </SocialContainer>
      </IntroductionContainer>
    </RootContainer>
  );
}

export default App;
